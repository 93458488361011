.box {
    padding: 60px 0;
    padding: 0;
    max-height: 750px;
    width: 100%;

    @media (max-width: 991px) {
        padding: 30px 0;
    }
}

.paralax {
    overflow: hidden;
}

.c-bg-parallax {
    background-position: center center;
    background-size: cover;
}

.c-content-area {
    width: 40%;
    height: 750px;
    margin-bottom: -750px;
}

.max>.c-bg-parallax {
    background-attachment: fixed;
    background-color: transparent;
    min-height: 100%;
    width: 60%;
    height: 750px;
    margin-bottom: -750px;
}

.max>.c-bg-parallax {
    background-attachment: fixed;
    background-color: transparent;
    min-height: 100%;
    width: 60%;
    height: 750px;
    margin-bottom: -750px;
}

.max>.c-feature-content {
    padding: 60px 0;
    height: auto;
    padding: 0;
    width: 40%;
    position: relative;
}

.max>.c-feature-content>.c-content-v-center {
    height: 750px;
    padding: 5rem 5rem 5rem 5rem;
}

.max>.c-feature-content>.c-content-v-center>.c-wrapper {
    display: table;
}

.max>.c-feature-content>.c-content-v-center>.c-wrapper>.c-body {
    display: table-cell;
    vertical-align: middle;
}

.min>.c-bg-parallax {
    width: 100%;
    height: 350px;
}

.min>.c-content-area {
    width: 0px;
}

.min>.c-feature-content {
    width: 100%;
    min-height: 50px;
}

.c-feature-content>.c-content-v-center>.c-wrapper>.c-body {
    font-family: 'Quicksand', sans-serif;
}