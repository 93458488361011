.login-layoud {
    width: 100vw;
    height: 100vh;
    position: "absolute";
    background-repeat: no-repeat;
    background-size: cover;
    top: 0;
    left: 0;
    display: grid;
    justify-content: center;
    grid-auto-rows: 50rem;
    align-items: center;
    justify-items: center;
    grid-template-columns: minmax(30rem, 1fr);
}