.super-modal {
    padding: 0 0 0 0 !important;
}

.super-modal>.modal-dialog {
    margin: 0 0 0 0 !important;
    padding: 0 0 0 0 !important;
    width: 100vw;
    height: 100vh;
    max-width: 100vw;
}

.super-modal>.modal-dialog>.modal-content {
    margin: 0 0 0 0 !important;
    width: 100vw;
    height: 100vh;
}